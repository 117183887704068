@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
	background-image: url('../../dist/img/bg.jpg');
	background-size: cover;
	background-position: bottom;
	background-repeat: no-repeat;
	min-height: 100vh;
	@include tablet-up{
		background-image: url('../../dist/img/bg-desktop.jpg');
		min-height: 1100px;
	}
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
		.side{
			flex: 1;
		}
	}
}

img{
	display: inline-block;
}

h2,h3,h4,p,ul{
	margin: 0;
}

.social{
	@include flex(row,center,center);
	gap: 15px;
	a{
		font-size: 30px;
	}
}

.mobile{
	@include tablet-up{
		display: none !important;
	}
}

.desktop{
	@include tablet-down{
		display: none !important;
	}
}

.coming-soon{
	position: relative;
	pointer-events: none;
	&::after{
		content: "Próximamente";
		position: absolute;
		bottom: -10px;
		padding: 4px;
		letter-spacing: 1px;
		right: -4px;
		background-color: #f7290c;
		font-size: 8px;
	}
}

header {
	padding: 10px 0;

	.menu-trigger{
		order: 2;
		display: block;
		border: none;
		background-color: transparent;
		border: none;
		border-style: none;
		border: solid 4px transparent;
		border-top-color: $white;
		border-bottom-color: $white;
		position: relative;
		width: 40px;
		height: 25px;
		transition: all .4s;
		&:before,&::after{
			content: '';
			display: block;
			width: 100%;
			height: 4px;
			top: calc(50% - 2px);
			left: 0;
			position: absolute;
			background-color: $white;
			transition: all .4s;
		}
		&.active{
			border-color: transparent;
			opacity: 0;
			&::after{
				transform: rotate(45deg);
			}
			&::before{
				transform: rotate(-45deg);
			}
		}
	}
	.split{
		@include flex(row,space-between,center);
		padding: 0 15px;
		.side{
			img{
				width: 60px;
			}
		}
	}
	.wrapper{
		.container{
			padding: 0;
		}
		transform: translateX(-100%);
		position: absolute;
			top: 0;
			left: 0;
		background-color: #000000d0;
		width: calc(100%  - 80px);
		height: 100vh;
		padding: 120px 20px 20px;
		transition: all .4s;
		.close-menu{
			padding: 0;
			font-size: 30px;
			border: none;
			background-color: transparent;
			color: $white;
			position: absolute;
			top: 20px;
			left: 20px;
			i{
				font-weight: 400;
			}
		}
		.nav{
			a{
				border-bottom: solid 1px $white;
				display: block;
				text-transform: uppercase;
				font-size: 18px;
				text-align: center;
				padding: 20px 15px;
				font-weight: 900;
			}
		}
		.social{
			@include flex(column,initial,initial);
			gap: 0;
			a{
				display: block;
				text-align: center;
				padding: 20px 15px;
				border-bottom: solid 1px $white;
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 900;
				@include flex(row,center,center);
				gap: 10px;
				span{
					font-family: Arial, Helvetica, sans-serif;
				}
			}
		}
		&.active{
			transform: translateX(0);
		}
	}

	@include tablet-up{
		padding: 0;
		.wrapper{
			transition: all 0s;
			position: unset;
			background-color: $white;
			transform: none;
			width: 100%;
			height: unset;
			padding: 10px 0;
			.container{
				max-width: 100%;
				@include flex(row,space-between,center);
				padding: 0 15px;
				gap: 20px
			}
			.nav{
				@include flex(row,flex-start,initial);
				gap: 30px;
				a{
					display: inline-block;
					padding: 6px 15px;
					background-color: $black;
					font-size: 16px;
					@include hover-focus{
						background-color: $gray;
					}
				}
			}
			.social{
				gap: 30px;
				@include flex(row,flex-end,center);
				a{
					color: $black;
					font-size: 20px;
					padding: 0;
				}
			}
		}
	}

}


main {

	.container{
		.wrapper{
			padding-top: 40px;
			text-align: center;
			img{
				display: block;
				margin: 0 auto;
				&.logo-cnco{
					height: 60px;
					margin-bottom: -40px;
				}
				&.logo-tour{
					width: 100%;
					max-width: 350px;
					margin-bottom: 30px;
				}
			}
			p{
				font-weight: 900;
				text-transform: uppercase;
				font-size: 20px;
				margin-bottom: 40px
			}
			.buttons{
				@include flex(column,center,center);
				gap: 10px;
				a{
					display: block;
					text-transform: uppercase;
					background-color: $white;
					color: $black;
					padding: 15px 20px;
					font-size: 18px;
					font-weight: 900;
					text-align: center;
					width: 100%;
					@include hover-focus{
						background-color: $gray;
					}
				}
			}
		}
	}

	@include tablet-up{
		.container{
			.wrapper{
				img{
					&.logo-cnco{
						margin-bottom: -60px;
					}
					&.logo-tour{
						margin-bottom: 0;
						max-width: 600px;
					}
				}
				.buttons{
					@include flex(row,center,center);
					a{
						max-width: 280px;
					}
				}
			}
		}
	}

	.popup{
		opacity: 0;
		transition: all .4s;
		pointer-events: none;
		&.active{
			opacity: 1;
			pointer-events: all;
		}
		position: fixed;
			top: 0;
			left: 0;
		width: 100%;
		background-color: #000000d0;
		.container{
			@include flex(row,center,center);
			height: 100vh;
			.wrapper{
				position: relative;
				background-image: url('../../dist/img/bg-popup.jpg');
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				padding: 40px 15px;
				border-radius: 15px;
				width: 100%;
				max-width: 600px;
				p{
					font-weight: 700;
					font-size: 18px;
					margin-bottom: 30px;
					line-height: 1.4;
					text-transform: uppercase;
				}
				h2{
					text-transform: uppercase;
					font-size: 32px;
					margin-bottom: 40px;
				}
				.popup-close{
					background-color: $white;
					color: $black;
					width: 40px;
					height: 40px;
					border: none;
					@include flex(column,center,center);
					font-size: 30px;
					border-radius: 60px;
					i{
						font-weight: 400;
					}
					position: absolute;
					bottom: -20px;
					left: calc(50% - 20px);
					@include hover-focus{
						background-color: $gray;
					}
				}
				video{
					width: 100%;
					height: auto;
					object-fit: cover;
				}
			}
		}

		&#newsletter{
			.container{
				.wrapper{
					max-width: 800px;
					iframe{
						width: 100%;
						height: calc(100vh - 140px);
					}
				}
			}
		}
	}

}

footer {
	.footer-logo{
		position: absolute;
		bottom: 20px;
		right: 20px;
		width: 60px;
	}
}